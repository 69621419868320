import React from "react"

import Layout from "../components/layout"
import {graphql} from "gatsby"
import Img from "gatsby-image"
import Partner from "../components/Partner";
import SEO from "../components/seo";

const IndexPage = ({data: {allDatoCmsPartner: partners, datoCmsPartnerspage: page}}) => {
  return <Layout>
    <SEO title='Partners' seo={page.seoMetaTags}/>
    <Img fluid={page.image.fluid}/>
    <div className='flex flex-col items-center'>
      <h1 className='font-title font-bold text-4xl uppercase mt-8 mb-2 text-center tracking-wider'>{page.title}</h1>
      <span className="font-sans font-light text-gray-800 text-center mx-2 sm:mx-6 lg:mx-32 mb-4 "
            dangerouslySetInnerHTML={{__html: page.description}}/>
      <div className="flex flex-wrap justify-center mb-16">
        {partners.edges.map(({node: p}) => <Partner key={p.id} p={p}/>
        )}
      </div>
    </div>
  </Layout>;
}

export default IndexPage

export const query = graphql`
    query Partners {
        allDatoCmsPartner(filter: {visible: {eq: true}},sort:{fields:[name]}) {
            edges {
                node {
                    id
                    name
                    slug
                    description
                    logo{
                        fixed(width:180, height:180, imgixParams: { auto: "compress,format" }) {
                            ...GatsbyDatoCmsFixed
                        }
                    }
                }
            }
        }
        datoCmsPartnerspage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            title
            description
            image {
                fluid(maxWidth: 1500, imgixParams: {fit: "crop", w:"1000", h:"300", crop:"top"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
        }
    }
`
